.richText {
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  span {
    white-space: pre-wrap;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}
